<template>
  <div class="newestNoticSty" v-loading="loading">

    <div style="padding: 50px 30px">
      <div class="newestNoticContent">
        <div v-for="item in noticeArray" :key="item.id" @click="jumpDetails(item.id)">
          <el-card shadow="hover" class="box-card" >
            <img :src="'http://www.wancode.net:8585/wancode/back/image/read/' + item.coverImageId" style="width: 300px;height: 150px;border-radius: 10px;">
            <div class="newsContent" style="position: relative;">
              <div class="title" style="float:right;">{{ item.title }}</div>
              <el-tooltip class="item" effect="dark" content="置顶信息" placement="bottom">
                <div class ="el-icon-info" style="display: block;
              width: 20px;
              height: 20px;
              position: absolute;
              top: 5px;
              right: 5px;
              background-size: 100% 100%;" v-if="item.sortFlag ==1"></div>
              </el-tooltip>

              <div class="sketch">{{ item.sketch }}</div>
              <p class="time">发布时间：{{ item.createTime }}</p>
              <!--							<div v-html="item.content" class="content"></div>-->
            </div>
            <br>

          </el-card>
        </div>
        <div v-if="haveMore" style="text-align: center;">
          <el-divider>加载中 <i class="el-icon-loading"></i></el-divider>
        </div>
        <div v-if="noMore" style="text-align: center;">
          <el-divider>我是有底线的。</el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNoticeInfo
} from "@/api/index.js"

export default {
  data() {
    return {
      loading: false,
      allNoticData: {
        total: 0
      },
      noticeArray: [],
      currentPage: 1,
      pageSize: 10,
      haveMore: false,
      noMore: false,
      isLoading: true
    }
  },
  methods: {
    getInfo() {
      this.loading = true
      let param = {
        title: '',
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      }
      getNoticeInfo(param).then(res => {
        if (res.code === "0000") {
          res.data.list.forEach(item => {
            this.noticeArray.push(item)
          })
          this.allNoticData = res.data
          this.loading = false
          this.haveMore = false
          this.isLoading = true
        }
      })
    },
    jumpDetails(id) {
      // console.log(id);
      this.$router.push({
        path: '/noticeDetails?id=' + id
      })
    }
  },
  mounted() {
    this.getInfo()
    let _this = this;
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //到了这个就可以进行业务逻辑加载后台数据了
        // _this.footerText = '我是有底线的';
        if (_this.allNoticData.total > _this.noticeArray.length) {
          if (_this.isLoading) {
            _this.isLoading = false
            _this.haveMore = true
            _this.currentPage = _this.currentPage + 1
            setTimeout(() => {
              // console.log("2")
              _this.getInfo()
            }, 1000);
            // console.log(_this.noticeArray)
            // console.log("到了底部");
          }
        } else {
          _this.haveMore = false
          _this.noMore = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-divider__text {
  // background-color: #f5f5f5;
  color: #808080;
}

.newestNoticSty {
  width: 1280px;
  background-color: #FFFFFF;
  margin: 50px auto;
  box-shadow: 10px 10px 5px #cccccc;
  position: relative;
  // overflow-y: scroll;

  // .newestNoticContent {
  // 	height: 460px;
  // 	overflow-y: scroll;
  // }
}


.box-card {
  text-align: left;
  margin-top: 10px;

  /deep/ .el-card__body {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    .newsContent {
      width: 100%;
      height: 150px;
      margin-left: 20px;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 18px;
        font-weight: 800;
        line-height: 30px;
        height: 35px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .sketch{
        font-size: 16px;
        color: #7e7e7e;
        line-height: 26px;
        height: 25px;
        //text-overflow: -o-ellipsis-lastline;
        //overflow: hidden;
        margin-top: -20px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .time {
        font-size: 16px;
        color: #333;
        line-height: 26px;
        height: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .content {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        // height: 40px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

}
</style>
